import { Button, Dialog, Form, Input, NavBar, Space, Tag, Toast } from "antd-mobile";
import { useEffect, useState } from "react";
import Server from "../../server/Server";
import URL from "../../server/URL";

let timer: string | number | NodeJS.Timeout | null | undefined = null;
const ForgetPwd = () => {

    const [formRef] = Form.useForm()
    const [loginType, setLoginType] = useState(0);
    const [isSend, setIsSend] = useState(false);
    const [codeSecond, setCodeSecond] = useState(120);

    const onSubmit = (values: any) => {
        var params = {
            tel: values.tel,
            code: values.code,
            password: values.password,
            passwordConfirm: values.passwordConfirm,
        };
        Server.Post(URL.User.ResetPwd, params).then((response: any) => {
            if (response.isSuccess) {
                Toast.show('密码重置成功');
                window.location.href = '/login';
            } else {
                Toast.show(response.returnMsg);
            }
        })
    }
    const getMsgCode = () => {
        const values = formRef.getFieldsValue()
        if (values.tel && values.tel != '') {
            var params = { tel: values.tel ?? '' };
            Server.Post(URL.User.GetMsgCode, params).then((response: any) => {
                if (response.isSuccess) {
                    setIsSend(true);
                }
            });
        } else {
            formRef.validateFields(['tel']);
        }
    }
    useEffect(() => {
        if (isSend) {
            setCodeSecond(120);
            startMsgCode();
        } else {
            stopMsgCode();
        }

    }, [isSend])

    const startMsgCode = () => {
        clearInterval(timer ?? undefined);
        timer = setInterval(() => {
            setCodeSecond((value) => {
                if (value == 0) {
                    setIsSend(false);
                    return 0;
                }
                return (value - 1);
            });
        }, 1000);
    }

    const stopMsgCode = () => {
        clearInterval(timer ?? undefined);
    }
    const back = () => {
        window.location.href = '/login';
    }

    return <div style={{ backgroundColor: 'whitesmoke', paddingBottom: '2px', marginTop: '35px' }}>
        <div style={{
            backgroundColor: 'white', position: 'fixed', top: 0, width: '100%'
            , height: '35px', zIndex: 9999
        }}>
            <NavBar back='返回' style={{ height: '35px', background: '#1677ff', color: 'white' }} onBack={back}>重置密码</NavBar>
        </div>
        <div style={{ padding: '5px' }}>
            <Form form={formRef} layout='horizontal' hasFeedback onFinish={onSubmit}
                footer={
                    <>
                        <Button block type='submit' color='primary' size='middle' >
                            重置密码
                        </Button>
                    </>
                }>
                <Form.Item name={'tel'} initialValue={''} rules={[{ required: true, pattern: new RegExp(/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/, 'g'), message: '请输入11位手机号码' }]}>
                    <Input placeholder="手机号码" />
                </Form.Item>
                <Form.Item name={'code'} initialValue={''} extra={<Button size='small' color='primary' disabled={isSend} onClick={() => getMsgCode()}>{isSend ? ('(' + codeSecond + 's)') : '获取验证码'}</Button>}
                    rules={[{ required: true, message: '请输入验证码' }]}>
                    <Input placeholder="短信验证码" />
                </Form.Item>
                <Form.Item name={'password'} initialValue={''} rules={[{ required: true, message: '请输入账户密码' }]}>
                    <Input placeholder="账户密码" type="password" />
                </Form.Item>
                <Form.Item name={'passwordConfirm'} initialValue={''} rules={[{ required: true, message: '请再次输入账户密码' }]}>
                    <Input placeholder="账户密码确认" type="password" />
                </Form.Item>
            </Form>
        </div>
    </div>
}
export default ForgetPwd;