import { Card, Ellipsis, Grid, InfiniteScroll, SearchBar, Space, Tabs, Tag, Toast } from "antd-mobile"
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import URL from "../../../server/URL";
import Server from "../../../server/Server";
import { SmileOutline, RightOutline } from 'antd-mobile-icons'

const ApplyAllocationDetailOrder = (props: any) => {
    const [data, setData] = useState<any>([]);
    const [total, setTotal] = useState<any>([]);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 5 });
    const [hasMore, setHasMore] = useState(false);

    async function loadMore() {
        if (props.applyAllocationID) {
            loadOrderData(true);
        }
    }

    useEffect(() => {
        if (props.applyAllocationID) {
            loadOrderData(false)
        }
    }, [props.applyAllocationID])


    const loadOrderData = (isMore: boolean) => {
        setHasMore(false);
        var request = {
            applyAllocationID: props.applyAllocationID,
            pageIndex: isMore ? page.pageIndex : 1,
            pageSize: page.pageSize,
            searchValue: ''
        };
        Server.Post(URL.ApplyOrder.QueryAllocationApplyOrderList, request).then((response: any) => {
            if (response.isSuccess) {
                if (isMore) {
                    setData((val: any) => [...val, ...response.data]);
                } else {
                    setData(response.data);
                }
                setPage({
                    pageIndex: page.pageIndex + 1,
                    pageSize: page.pageSize
                })
                setHasMore(response.data && response.data.length > 0 ? true : false);
                setTotal(response.total);
                window.dispatchEvent(new Event('resize'));
            } else {
                Toast.show(response.returnMsg);
            }
            setHasMore(response.data && response.data.length > 0 ? true : false);
        })
    }

    return <>
        <Card
            title={<>
                <Space>
                    <Tag>{'共' + total + '笔'}</Tag>
                    <Tag color={'warning'}>申报：{props.applyAllocation?.usedAmount ?? 0}万元</Tag>
                </Space>
            </>}
            style={{ borderRadius: 0, padding: '5px' }}
            bodyStyle={{ margin: 0, padding: 0, backgroundColor: 'ButtonFace' }}
        >
            {(data ?? []).map((item: any) => {
                return <Card title={
                    <Space>
                        <a onClick={() => {
                            window.location.href = '/applyOrderDetail?id=' + item.applyOrderID;
                        }}>申报单号：{item.applyOrderNO}</a>
                    </Space>
                }
                    extra={<Space>
                        <a style={{ fontWeight: 'bold' }} onClick={() => {
                            window.location.href = '/applyOrderDetail?id=' + item.applyOrderID
                        }}>查看详情<RightOutline /></a>
                    </Space>}
                    style={{ borderRadius: 0, padding: 0, marginBottom: '2px' }}
                    bodyStyle={{ margin: 0, padding: 0 }}
                >
                    <div>
                        <Grid columns={4}>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        申报时间:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={dayjs(item.applyOrderNO.applyTime).format('YYYY-MM-DD HH:mm')} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        申报类型:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.applyType} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        发送时间:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.sendTime?dayjs(item.sendTime).format('YYYY-MM-DD'):''} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        保额:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.insureAmount + '万元'} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        承运时间:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={dayjs(item.carrierTime).format('YYYY-MM-DD')} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        货物品名:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.partName} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        货物包装:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.packages} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        货物件数:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.eaQty} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        始发地:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.startAddress} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        目的地:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.destAddress} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        车牌号:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.truckNO} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        运单号:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.transportNO} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        说明:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开'
                                            collapseText='收起' direction='end' content={item.remark} />
                                    </span>
                                </div>
                            </Grid.Item>
                        </Grid>
                    </div>
                    <div style={{
                        height: '30px',
                        borderTop: '1px solid #e5e5e5',
                        padding: '5px 0'
                    }} onClick={e => e.stopPropagation()}>
                        <Space>
                            <Tag color={item.stateEnumn == 0 ? 'warning' : 'success'}>{item.stateEnumn == 0 ? '未申报' : '已申报'}</Tag>
                            <Tag color={item.sendStateEnumn == 0 ? 'warning' : 'success'}>{item.sendStateEnumn == 0 ? '未发送' : '已发送'}</Tag>
                        </Space>
                    </div>
                </Card>
            })
            }
        </Card>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </>
}
export default ApplyAllocationDetailOrder;