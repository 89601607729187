import { Button, Card, Image, Grid, Space, Tag, Toast, Ellipsis, InfiniteScroll, SearchBar, FloatingBubble } from "antd-mobile";
import BottomTab from "../../../components/BottionTab/BottomTab";
import TopNavBar from "../../../components/TopNavBar/TopNavBar";
import { AntOutline, MessageFill, RightOutline, UpCircleOutline } from 'antd-mobile-icons'
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import dayjs from 'dayjs'

const ApplyAllocation = () => {
    const [searchValue, setSearchValue] = useState('');
    const [data, setData] = useState<any>([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 5 });
    const [hasMore, setHasMore] = useState(false);

    async function loadMore() {
        await loadData(true);
    }

    useEffect(() => {
        loadData(false);
    }, [searchValue])

    const loadData = (isMore: boolean) => {
        setHasMore(false);
        var params = {
            searchValue: searchValue,
            pageIndex: isMore ? page.pageIndex : 1,
            pageSize: page.pageSize
        }
        Server.Post(URL.ApplyAllocation.QueryApplyAllocationList, params, true)
            .then((response: any) => {
                if (response.isSuccess) {
                    if (isMore) {
                        setData((val: any) => [...val, ...response.data]);
                    } else {
                        setData(response.data);
                    }
                    setPage({
                        pageIndex: isMore ? page.pageIndex + 1 : 2,
                        pageSize: page.pageSize
                    })
                    setHasMore(response.data && response.data.length > 0);
                    setTotal(response.total);
                    window.dispatchEvent(new Event('resize'));
                } else {
                    Toast.show(response.returnMsg);
                }
            })
    }

    return <div style={{ marginTop: '100px', marginBottom: '60px' }}>
        <TopNavBar title={'我的保单'} />
        <div style={{
            height: '30px', padding: '10px', margin: '5px 0',
            backgroundColor: 'white',
            position: 'fixed', top: '40px', width: 'calc(100% - 20px)', zIndex: 99
        }}>
            <Space style={{ padding: '8px 0' }}>
                <Tag>共{total}笔</Tag>
            </Space>
            <SearchBar style={{ float: 'right', width: '200px' }} placeholder={'请输入搜索条件'}
                onChange={(e) => {
                    setSearchValue(e ?? '');
                }}
                onClear={() => {
                    setSearchValue('');
                }}
                onSearch={(e) => {
                    loadData(false);
                }} />
        </div>
        <div>
            {(data ?? []).map((item: any) => {
                return <Card
                    title={
                        <div>
                            <Space>
                                <a style={{ fontWeight: 'bold' }} onClick={() => {
                                    window.location.href = '/applyAllocationDetail?id=' + item.applyAllocationID
                                }}>保单编码：{item.applyAllocationNO}</a>
                            </Space>
                        </div>
                    }
                    extra={<Space>
                        <a style={{ fontWeight: 'bold' }} onClick={() => {
                            window.location.href = '/applyAllocationDetail?id=' + item.applyAllocationID
                        }}>查看详情<RightOutline /></a>
                    </Space>}
                    style={{ padding: '0px 5px', marginBottom: '5px', borderRadius: 0 }}
                    bodyStyle={{ margin: 0, padding: 0 }}
                >
                    <div style={{ margin: '5px 0', border: '1px solid #e5e5e5' }}>
                        <Grid columns={4}>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        协议号:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.agreementNO} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        投保人:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.insureBy} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        被保人:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.insuredBy} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        保险公司:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.companyName} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        产品名称:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.applyProductName} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        生效日期:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={dayjs(item.effectTime).format('YYYY-MM-DD')} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        失效日期:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={dayjs(item.expireTime).format('YYYY-MM-DD')} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        投保限额:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.singleLimit + '万元'} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        总保额:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.amount + '万元'} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        剩余保额:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={(item.amount - item.usedAmount) + '万元'} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        免赔额:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开'
                                            collapseText='收起' direction='end' content={item.deductibleAmountDesc} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        承保区域:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开'
                                            collapseText='收起' direction='end' content={item.insureArea} ></Ellipsis>
                                    </span>
                                </div>
                            </Grid.Item>
                        </Grid>
                    </div>
                    <div style={{
                        height: '30px',
                        borderTop: '1px solid #e5e5e5',
                        padding: '5px 0'
                    }} onClick={e => e.stopPropagation()}>
                        <Space>
                            {(item?.tags ?? []).map((item: any) => {
                                return <Tag color={item.color}>{item.name}</Tag>;
                            })}
                        </Space>
                        <Space style={{ float: 'right' }}>
                            <Button
                                size='small'
                                color='primary'
                                disabled={dayjs(item.expireTime) < dayjs()}
                                onClick={() => {
                                    window.location.href = '/applyOrderCreate?aid=' + item.applyAllocationID
                                }}
                            >
                                立即申报
                            </Button>
                        </Space>
                    </div>
                </Card>
            })}
            {/* <FloatingBubble
                style={{
                    '--initial-position-bottom': '60px',
                    '--initial-position-right': '24px',
                }}
                onClick={() => { document.documentElement.scrollTop = 0; }}
            >
                <UpCircleOutline fontSize={22} />
            </FloatingBubble> */}
        </div>
        <BottomTab />
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </div>
}
export default ApplyAllocation;