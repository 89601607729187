import { Card, Ellipsis, Grid, InfiniteScroll, SearchBar, Space, Tabs, Tag, Toast } from "antd-mobile"
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import URL from "../../../server/URL";
import Server from "../../../server/Server";
import { SmileOutline, RightOutline } from 'antd-mobile-icons'

const ApplyAllocationDetailConsume = (props: any) => {
    const [data, setData] = useState<any>([]);
    const [total, setTotal] = useState<any>([]);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 5 });
    const [hasMore, setHasMore] = useState(false);

    async function loadMore() {
        if (props.applyAllocationID) {
            loadConsumeData(true);
        }
    }

    useEffect(() => {
        if (props.applyAllocationID) {
            loadConsumeData(false)
        }
    }, [props.applyAllocationID])

    const loadConsumeData = (isMore: boolean) => {
        setHasMore(false);
        var request = {
            applyAllocationID: props.applyAllocationID,
            pageIndex: isMore ? page.pageIndex : 1,
            pageSize: page.pageSize
        };
        Server.Post(URL.ApplyConsume.QueryApplyConsumeList, request).then((response: any) => {
            if (response.isSuccess) {
                const newData = isMore ? data.concat(response.data) : response.data;
                setData(newData);
                setPage({
                    pageIndex: page.pageIndex + 1,
                    pageSize: page.pageSize
                })
                setTotal(response.total);
            } else {
                Toast.show(response.returnMsg);
            }
            setHasMore(response.data && response.data.length > 0 ? true : false);
        })
    }

    return <><Card
        title={<>
            <Space>
                <Tag>{'共' + total + '笔'}</Tag>
                <Tag color={'success'}>充值：{props.applyAllocation?.amount}万元</Tag>
                <Tag color={'warning'}>申报：{props.applyAllocation?.usedAmount}万元</Tag>
            </Space>
        </>}
        style={{ borderRadius: 0, padding: '5px', margin: 0 }}
        bodyStyle={{ margin: 0, padding: 0, backgroundColor: 'ButtonFace' }}
    >
        {(data ?? []).map((item: any) => {
            return <Card
                style={{ borderRadius: 0, padding: 0, margin: 0, paddingBottom: '5px' }}
                bodyStyle={{ margin: 0, padding: 0 }}
            >
                <div>
                    <Grid columns={4}>
                        <Grid.Item span={4}>
                            <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                    时间:
                                </div>
                                <span>
                                    <Ellipsis direction='end' content={dayjs(item.createTime).format('YYYY-MM-DD HH:mm')} />
                                </span>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={2}>
                            <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                    单据:
                                </div>
                                <span>
                                    <Ellipsis direction='end' content={item.consumeTypeEnum == 0 ? item.applyAllocationNO : item.applyOrderNO} />
                                </span>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={2}>
                            <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                    类型:
                                </div>
                                <span>
                                    <Tag color={item.consumeTypeEnum == 0 ? 'success' : 'warning'}>{item.consumeTypeEnum == 0 ? '充值' : '申报'}</Tag>
                                </span>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={2}>
                            <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                    金额:
                                </div>
                                <span>
                                    <Ellipsis direction='end' content={item.consumeAmount + '万元'} />
                                </span>
                            </div>
                        </Grid.Item>
                        <Grid.Item span={2}>
                            <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                    余额:
                                </div>
                                <span>
                                    <Ellipsis direction='end' content={item.allocationAmount + '万元'} />
                                </span>
                            </div>
                        </Grid.Item>
                    </Grid>
                </div>
            </Card>
        })
        }
    </Card>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </>
}
export default ApplyAllocationDetailConsume;