import { Badge, NavBar, TabBar } from 'antd-mobile'
import {
    AddCircleOutline,
    HandPayCircleOutline,
    UnorderedListOutline,
} from 'antd-mobile-icons'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

const BottomTab = () => {
    const location = useLocation()
    const { pathname } = location;

    const tabs = [
        {
            key: '/applyAllocation',
            title: '我的保单',
            icon: <HandPayCircleOutline />,
            badge: '5',
        },
        {
            key: '/applyOrderCreate',
            title: '立即申报',
            icon: <AddCircleOutline />,
            badge: '5',
        },
        {
            key: '/applyOrder',
            title: '申报记录',
            icon: <UnorderedListOutline />,
            badge: '99+',
        }
    ]

    const setRouteActive = (value: string) => {
        window.location.href = value;
    }

    return <>
        <div style={{
            position: 'fixed', bottom: '0', width: '100%'
            , zIndex: 999, color: 'white'
            , backgroundColor: 'white'
        }}>
            <TabBar activeKey={pathname == '/' ? tabs[0].key : pathname} onChange={value => setRouteActive(value)}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        </div>
    </>
}
export default BottomTab;