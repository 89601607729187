import { Badge, NavBar, TabBar } from 'antd-mobile'
import {
    AddCircleOutline,
    HandPayCircleOutline,
    UnorderedListOutline,
} from 'antd-mobile-icons'
import { useEffect, useState } from 'react'
import { Route, Router, Routes } from 'react-router-dom'
import ApplyAllocation from '../apply/applyAllocation/ApplyAllocation'
import ApplyAllocationDetail from '../apply/applyAllocation/ApplyAllocationDetail'
import ApplyOrder from '../apply/applyOrder/ApplyOrder'
import ApplyOrderCreate from '../apply/applyOrder/ApplyOrderCreate'
import ApplyOrderDetail from '../apply/applyOrder/ApplyOrderDetail'
import Error404 from '../error/Error404'

const Main = () => {
    useEffect(() => {
        let tokenKey = process.env.REACT_APP_TOKEN ?? '';
        var token = localStorage.getItem(tokenKey);
        if (!token) {
            window.location.href = '/login';
        }
    }, [])

    return <>
        <div>
            <Routes>
                <Route path='/' element={<ApplyAllocation />} />
                <Route path='/applyAllocation' element={<ApplyAllocation />} />
                <Route path='/applyAllocationDetail' element={<ApplyAllocationDetail />} />
                <Route path='/applyOrder' element={<ApplyOrder />} />
                <Route path='/applyOrderCreate' element={<ApplyOrderCreate />} />
                <Route path='/applyOrderDetail' element={<ApplyOrderDetail />} />
                <Route path='/*' element={<Error404 />} />
            </Routes>
        </div>
    </>
}
export default Main;