import { Button, Card, Ellipsis, FloatingBubble, Grid, InfiniteScroll, Modal, SearchBar, Space, Tag, Toast } from "antd-mobile";
import BottomTab from "../../../components/BottionTab/BottomTab";
import TopNavBar from "../../../components/TopNavBar/TopNavBar";
import { AntOutline, RightOutline, UpCircleOutline } from 'antd-mobile-icons'
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { useEffect, useState } from "react";
import dayjs from 'dayjs';

const ApplyOrder = () => {
    const [searchValue, setSearchValue] = useState('');
    const [data, setData] = useState<any>([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 5 });
    const [hasMore, setHasMore] = useState(false);

    async function loadMore() {
        await loadData(true);
    }

    useEffect(() => {
        loadData(false);
    }, [searchValue])

    const loadData = (isMore: boolean) => {
        setHasMore(false);
        var params = {
            searchValue: searchValue,
            pageIndex: isMore ? page.pageIndex : 1,
            pageSize: page.pageSize
        }
        Server.Post(URL.ApplyOrder.QueryApplyOrderList, params, true)
            .then((response: any) => {
                if (response.isSuccess) {
                    if (isMore) {
                        setData((val: any) => [...val, ...response.data]);
                    } else {
                        setData(response.data);
                    }
                    setPage({
                        pageIndex: isMore ? page.pageIndex + 1 : 2,
                        pageSize: page.pageSize
                    })
                    setHasMore(response.data && response.data.length > 0);
                    setTotal(response.total);
                    window.dispatchEvent(new Event('resize'));
                } else {
                    Toast.show(response.returnMsg);
                }
            })
    }

    const submitApplyOrder = (record: any) => {
        Modal.confirm({
            title: '提交申报确认',
            confirmText: '确定',
            cancelText: '取消',
            content: <div>
                <p>申报订单[{record.applyOrderNO}]，确认要提交申报，一旦提交申报将不能恢复，请慎重操作</p>
                <p>确定：提交申报</p>
                <p>取消：放弃提交</p>
            </div>,
            onConfirm: () => {
                var request = {
                    applyOrderID: record.applyOrderID,
                    applyOrderNO: record.applyOrderNO
                };
                Server.Post(URL.ApplyOrder.SubmitApplyOrder, request).then((response: any) => {
                    if (response.isSuccess) {
                        Toast.show('提交申报成功');
                        loadData(false);
                    } else {
                        Toast.show(response.returnMsg);
                    }
                })
            },
            onCancel: () => {

            }
        })
    }

    const deleteApplyOrder = (record: any) => {
        Modal.confirm({
            title: '删除确认',
            confirmText: '确定',
            cancelText: '取消',
            content: <div>
                <p>确认删除申报单，一旦删除将无法恢复？</p>
                <p>确定：立即删除</p>
                <p>取消：放弃删除</p>
            </div>,
            onConfirm: () => {
                var request = { applyOrderID: record.applyOrderID };
                Server.Post(URL.ApplyOrder.DeleteApplyOrder, request).then((response: any) => {
                    if (response.isSuccess) {
                        Toast.show('删除成功');
                        loadData(false);
                    } else {
                        Toast.show(response.returnMsg);
                    }
                })
            },
            onCancel: () => {

            }
        })
    }

    return <div style={{ marginTop: '100px', marginBottom: '60px' }}>
        <TopNavBar title={'申报记录'} />
        <div style={{
            height: '30px', padding: '10px', margin: '5px 0',
            backgroundColor: 'white',
            position: 'fixed', top: '40px', width: 'calc(100% - 20px)', zIndex: 99
        }}>
            <Space style={{ padding: '8px 0' }}>
                <Tag>共{total}笔</Tag>
            </Space>
            <SearchBar style={{ float: 'right', width: '200px' }} placeholder={'请输入搜索条件'}
                onChange={(e) => {
                    setSearchValue(e ?? '');
                }}
                onClear={() => {
                    setSearchValue('');
                }}
                onSearch={(e) => {
                    loadData(false);
                }} />
        </div>
        <div>
            {(data ?? []).map((item: any) => {
                return <Card
                    title={
                        <div>
                            <Space>
                                <a style={{ fontWeight: 'bold' }} onClick={() => {
                                    window.location.href = '/applyOrderDetail?id=' + item.applyOrderID
                                }}>申报单号：{item.applyOrderNO}</a>
                            </Space>
                        </div>
                    }
                    extra={<Space>
                        <a style={{ fontWeight: 'bold' }} onClick={() => {
                            window.location.href = '/applyOrderDetail?id=' + item.applyOrderID
                        }}>查看详情<RightOutline /></a>
                    </Space>}
                    style={{ padding: '0px 5px', marginBottom: '5px', borderRadius: 0 }}
                    bodyStyle={{ margin: 0, padding: 0 }}
                >
                    <div style={{ margin: '5px 0', border: '1px solid #e5e5e5' }}>
                        <Grid columns={4}>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        申报日期:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={dayjs(item.applyTime).format('YYYY-MM-DD HH:mm')} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        申报状态:
                                    </div>
                                    <span>
                                        <Tag color={item.stateEnum == 0 ? 'warning' : 'success'}>{item.state}</Tag>
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        发送日期:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.sendTime ? dayjs(item.sendTime).format('YYYY-MM-DD HH:mm') : ''} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        发送状态:
                                    </div>
                                    <span>
                                        <Tag color={item.sendStateEnum == 0 ? 'warning' : 'success'}>{item.sendState}</Tag>
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        协议号:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.agreementNO} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        保单编码:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开'
                                            collapseText='收起' direction='end' content={item.applyAllocationNO} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        承运日期:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={dayjs(item.carrierTime).format('YYYY-MM-DD')} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        货物品名:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.partName} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        货物包装:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.packages} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        货物件数:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.eaQty} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        始发地:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={item.startAddress} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        目的地:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开'
                                            collapseText='收起' direction='end' content={item.destAddress} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        车牌号:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开'
                                            collapseText='收起' direction='end' content={item.truckNO} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        运单号:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开' collapseText='收起' direction='end' content={item.transportNO} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        保额:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开'
                                            collapseText='收起' direction='end' content={item.insureAmount + '万元'} />
                                    </span>
                                </div>
                            </Grid.Item>
                        </Grid>
                    </div>
                    <div style={{
                        height: '30px',
                        borderTop: '1px solid #e5e5e5',
                        padding: '5px 0',
                        display: item.stateEnum == 1 && item.sendStateEnum == 1 ? 'none' : 'block'
                    }} onClick={e => e.stopPropagation()}>
                        <Space style={{ float: 'right' }}>
                            <Button size='small' onClick={() => {
                                window.location.href = '/applyOrderCreate?id=' + item.applyOrderID + '&&aid=' + item.applyAllocationID
                            }}>编辑</Button>
                            <Button color='primary' size='small' onClick={() => {
                                submitApplyOrder(item);
                            }}>申报</Button>
                            <Button color='danger' size='small' onClick={() => {
                                deleteApplyOrder(item);
                            }}>删除</Button>
                        </Space>
                    </div>
                </Card>
            })}
        </div>
        {/* <FloatingBubble
            style={{
                '--initial-position-bottom': '60px',
                '--initial-position-right': '24px',
            }}
            onClick={() => { document.documentElement.scrollTop = 0; }}
        >
            <UpCircleOutline fontSize={22} />
        </FloatingBubble> */}
        <BottomTab />
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </div>
}
export default ApplyOrder;