import { Button, Result } from "antd-mobile";


const Error404 = () => {
    const onBack = () => {
        window.history.back();
    }

    return <>
        <Result
            status='error'
            title='404错误'
            description={<>
                您所访问的页面不存在.
                <br />
                {<Button color="primary" onClick={onBack}>返回</Button>}
            </>}
        /></>
}
export default Error404;