import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/main.css';
import './styles/loading.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import zhCN from 'antd-mobile/es/locales/zh-CN';
import { ConfigProvider } from 'antd-mobile';
import Main from './views/main/Main';
import Login from './views/login/Login';
import Register from './views/register/Register';
import ForgetPwd from './views/forgetPwd/ForgetPwd';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ConfigProvider locale={zhCN}>
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/forgetpwd' element={<ForgetPwd />} />
        <Route path='/*' element={<Main />} />
      </Routes>
    </BrowserRouter>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
