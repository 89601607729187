import { Button, Card, Ellipsis, Grid, InfiniteScroll, Input, Result, SearchBar, Space, Tabs, Tag, Toast } from "antd-mobile";
import TopNavBar from "../../../components/TopNavBar/TopNavBar";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import EUtils from "../../../utils/EUtils";
import dayjs from 'dayjs';
import ApplyAllocationDetailOrder from "./ApplyAllocationDetailOrder";
import ApplyAllocationDetailConsume from "./ApplyAllocationDetailCosume";

const ApplyAllocationDetail = () => {
    const [applyAllocationID, setApplyAllocationID] = useState(null);
    const [applyAllocation, setApplyAllocation] = useState<any>(null);

    useEffect(() => {
        var params = EUtils.getUrlParams();
        if (params) {
            setApplyAllocationID(params.id);
        }
    }, [])

    useEffect(() => {
        if (applyAllocationID) {
            loadData();
        }
    }, [applyAllocationID])

    const loadData = () => {
        var request = { applyAllocationID: applyAllocationID };
        Server.Post(URL.ApplyAllocation.QueryApplyAllocation, request).then((response: any) => {
            if (response.isSuccess) {
                if (!applyAllocationID) {
                    setApplyAllocationID(response.applyAllocationID);
                }
                setApplyAllocation(response);
            } else {
                Toast.show(response.returnMsg);
            }
        })
    }

    return <div style={{ marginTop: '40px' }}>
        <TopNavBar back title={'保单详情'} />
        <div>
            <Card
                title={'保单信息'}
                extra={<>
                    <Space>
                        {(applyAllocation?.tags ?? []).map((item: any) => {
                            return <Tag color={item.color}>{item.name}</Tag>;
                        })}
                    </Space>
                </>}
                style={{ borderRadius: 0, padding: '5px', marginBottom: '5px' }}
                bodyStyle={{ margin: 0, padding: 0 }}
            >
                <Grid columns={4}>
                    <Grid.Item span={4}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                协议号:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyAllocation?.agreementNO} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                投保人:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyAllocation?.insureBy} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                被保人:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyAllocation?.insuredBy} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                保险公司:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyAllocation?.companyName} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                产品名称:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyAllocation?.applyProductName} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                生效日期:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={dayjs(applyAllocation?.effectTime).format('YYYY-HH-DD')} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                失效日期:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={dayjs(applyAllocation?.expireTime).format('YYYY-HH-DD')} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                投保限额:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyAllocation?.singleLimit + '万元'} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                总保额:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyAllocation?.amount + '万元'} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                剩余保额:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={(applyAllocation?.amount - applyAllocation?.usedAmount) + '万元'} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                免赔额:
                            </div>
                            <span>
                                <Ellipsis expandText='展开'
                                    collapseText='收起' direction='end' content={applyAllocation?.deductibleAmountDesc} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                承保区域:
                            </div>
                            <span>
                                <Ellipsis expandText='展开'
                                    collapseText='收起' direction='end' content={applyAllocation?.insureArea} />
                            </span>
                        </div>
                    </Grid.Item>
                </Grid>
            </Card>
            <Tabs style={{ '--content-padding': '0px' }} defaultActiveKey='0'>
                <Tabs.Tab title={'申报记录'} key='0' >
                    <ApplyAllocationDetailOrder
                        applyAllocationID={applyAllocationID}
                        applyAllocation={applyAllocation} />
                </Tabs.Tab>
                <Tabs.Tab title={'消费记录'} key='1'>
                    <ApplyAllocationDetailConsume
                        applyAllocationID={applyAllocationID}
                        applyAllocation={applyAllocation} />
                </Tabs.Tab>
            </Tabs>
        </div>
    </div >
}
export default ApplyAllocationDetail;