import { Button, NavBar } from "antd-mobile";

const TopNavBar = (props: any) => {
    return <div style={{
        backgroundColor: 'rgb(22, 119, 255)', color: 'white', position: 'fixed'
        , top: 0, width: '100%', zIndex: 999
    }}>
        <NavBar back={props.back ? '返回' : null}
            onBack={() => {
                window.history.back()
            }}
        >
            {props.title ?? '56申报平台'}
        </NavBar>
    </div>
}
export default TopNavBar;