let BaseUrl = '/api/';
let ApplyAllocationUrl = BaseUrl + 'ApplyAllocation/';
let ApplyOrderUrl = BaseUrl + 'ApplyOrder/';
let ApplyConsumeUrl = BaseUrl + 'ApplyConsume/';
let ApplyEmailUrl = BaseUrl + 'ApplyEmail/';

const URL = {
    User: {
        UserLogin: BaseUrl + 'User/UserLogin',
        GetUserInfo: BaseUrl + 'User/GetUserInfo',
        UpdateUserInfo: BaseUrl + 'User/UpdateUserInfo',
        LogUserBehavior: BaseUrl + 'User/LogUserBehavior',
        GetMsgCode: BaseUrl + 'User/GetMsgCode',
        Register: BaseUrl + 'User/Register',
        ResetPwd: BaseUrl + 'User/ResetPwd',
    },
    ApplyAllocation: {
        QueryApplyAllocationList: ApplyAllocationUrl + 'QueryApplyAllocationList',
        QueryApplyAllocation: ApplyAllocationUrl + 'QueryApplyAllocation',
    },
    ApplyOrder: {
        QueryApplyOrderList: ApplyOrderUrl + 'QueryApplyOrderList',
        QueryAllocationApplyOrderList: ApplyOrderUrl + 'QueryAllocationApplyOrderList',
        QueryApplyOrder: ApplyOrderUrl + 'QueryApplyOrder',
        SaveApplyOrder: ApplyOrderUrl + 'SaveApplyOrder',
        SaveAndSubmitApplyOrder: ApplyOrderUrl + 'SaveAndSubmitApplyOrder',
        SubmitApplyOrder: ApplyOrderUrl + 'SubmitApplyOrder',
        DeleteApplyOrder: ApplyOrderUrl + 'DeleteApplyOrder',
        DownLoadTemplate: ApplyOrderUrl + 'DownLoadTemplate',
        UpLoadFiles: ApplyOrderUrl + 'UpLoadFiles',
    },
    ApplyConsume: {
        QueryApplyConsumeList: ApplyConsumeUrl + 'QueryApplyConsumeList',
    },
    ApplyEmail: {
        QueryApplyEmailList: ApplyEmailUrl + 'QueryApplyEmailList',
    }
}

export default URL;