import { Button, Card, DatePicker, Ellipsis, Form, Grid, InfiniteScroll, Input, Modal, Picker, Popup, Result, Selector, Space, Stepper, Tag, Toast } from "antd-mobile";
import TopNavBar from "../../../components/TopNavBar/TopNavBar";
import {
    MoreOutline,
    CloseOutline
} from 'antd-mobile-icons';
import dayjs from 'dayjs'
import { useEffect, useState } from "react";
import { PickerDate } from "antd-mobile/es/components/date-picker/util";
import EUtils from "../../../utils/EUtils";
import URL from "../../../server/URL";
import Server from "../../../server/Server";

const ApplyOrderCreate = () => {
    const [formRef] = Form.useForm()

    const [visible3, setVisible3] = useState(false)

    const [applyType, setApplyType] = useState<any>({ value: '0', label: '即时申报' });
    const [carrierTime, setCarrierTime] = useState<any>(new Date());
    const [requiredTime, setRequiredTime] = useState<any>(new Date());
    const [packages, setPackage] = useState<any>({ value: '其他形状包装', label: '其他形状包装' });
    const [insureAmount, setInsureAmount] = useState<number>(1);

    const [requiredTimeVisible, setRequiredTimeVisible] = useState(false);
    const [carrierTimeVisible, setCarrierTimeVisible] = useState(false);
    const [applyTypeVisible, setApplyTypeVisible] = useState(false);
    const [packagesVisible, setPackagesVisible] = useState(false);

    const [validTruckNO, setValidTruckNO] = useState(true);
    const [validTransportNO, setValidTransportNO] = useState(true);

    const [applyAllocationID, setApplyAllocationID] = useState(null);
    const [applyAllocation, setApplyAllocation] = useState<any>(null);

    const [applyOrderID, setApplyOrderID] = useState(null);
    const [stateEnum, setStateEnum] = useState(0);

    const [data, setData] = useState<any>([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 5 });
    const [hasMore, setHasMore] = useState(false);

    async function loadMore() {
        await loadSelectData(true);
    }

    const loadSelectData = (isMore: boolean) => {
        setHasMore(false);
        var params = {
            searchValue: '',
            pageIndex: isMore ? page.pageIndex : 1,
            pageSize: page.pageSize
        }
        Server.Post(URL.ApplyAllocation.QueryApplyAllocationList, params, true)
            .then((response: any) => {
                if (response.isSuccess) {
                    if (isMore) {
                        setData((val: any) => [...val, ...response.data]);
                    } else {
                        setData(response.data);
                    }
                    setPage({
                        pageIndex: isMore ? page.pageIndex + 1 : 2,
                        pageSize: page.pageSize
                    })
                    setHasMore(response.data && response.data.length > 0);
                    setTotal(response.total);
                    window.dispatchEvent(new Event('resize'));
                } else {
                    Toast.show(response.returnMsg);
                }
            })
    }

    const mockContent = (
        <div style={{ padding: '0', height: '100%', width: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
            <Card title={'切换保单(共' + total + '笔)'}
                extra={<>
                    <a onClick={() => { setVisible3(false) }}><CloseOutline /></a>
                </>}
                headerStyle={{
                    color: '#1677ff',
                    fontWeight: 'bold',
                    height: '35px',
                    position: 'fixed',
                    backgroundColor: 'white',
                    zIndex: 999,
                    width: 'calc(100% - 28px)',
                    marginRight: '28px'
                }}
                bodyStyle={{ margin: 0, paddingTop: 40 }}>
                {(data ?? []).map((item: any) => {
                    return <Card
                        title={
                            <div>
                                <Space>
                                    <a style={{ fontWeight: 'bold' }}>保单编码：{item?.applyAllocationNO}</a>
                                </Space>
                            </div>
                        }
                        extra={<Space>
                            {(item?.tags ?? []).map((item: any) => {
                                return <Tag color={item.color}>{item.name}</Tag>;
                            })}
                        </Space>}
                        style={{ padding: '0px 5px', marginBottom: '5px', borderRadius: 0 }}
                        bodyStyle={{ margin: 0, padding: 0 }}
                    >
                        <div style={{ margin: '5px 0', border: '1px solid #e5e5e5' }}>
                            <Grid columns={4}>
                                <Grid.Item span={4}>
                                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            协议号:
                                        </div>
                                        <span>
                                            <Ellipsis direction='end' content={item.agreementNO} />
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={4}>
                                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            投保人:
                                        </div>
                                        <span>
                                            <Ellipsis direction='end' content={item.insureBy} />
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={4}>
                                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            被保人:
                                        </div>
                                        <span>
                                            <Ellipsis direction='end' content={item.insuredBy} />
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={4}>
                                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            保险公司:
                                        </div>
                                        <span>
                                            <Ellipsis direction='end' content={item.companyName} />
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={4}>
                                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            产品名称:
                                        </div>
                                        <span>
                                            <Ellipsis direction='end' content={item.applyProductName} />
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={2}>
                                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            生效日期:
                                        </div>
                                        <span>
                                            <Ellipsis direction='end' content={dayjs(item.effectTime).format('YYYY-MM-DD')} />
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={2}>
                                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            失效日期:
                                        </div>
                                        <span>
                                            <Ellipsis direction='end' content={dayjs(item.expireTime).format('YYYY-MM-DD')} />
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={4}>
                                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            投保限额:
                                        </div>
                                        <span>
                                            <Ellipsis direction='end' content={item.singleLimit + '万元'} />
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={2}>
                                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            总保额:
                                        </div>
                                        <span>
                                            <Ellipsis direction='end' content={item.amount + '万元'} />
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={2}>
                                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            剩余保额:
                                        </div>
                                        <span>
                                            <Ellipsis direction='end' content={(item.amount - item.usedAmount) + '万元'} />
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={4}>
                                    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            免赔额:
                                        </div>
                                        <span>
                                            <Ellipsis expandText='展开'
                                                collapseText='收起' direction='end' content={item.deductibleAmountDesc} />
                                        </span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item span={4}>
                                    <div>
                                        <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                            承保区域:
                                        </div>
                                        <span>
                                            <Ellipsis expandText='展开'
                                                collapseText='收起' direction='end' content={item.insureArea} ></Ellipsis>
                                        </span>
                                    </div>
                                </Grid.Item>
                            </Grid>
                        </div>
                        <div style={{
                            height: '30px',
                            borderTop: '1px solid #e5e5e5',
                            padding: '5px 0',
                        }} onClick={e => e.stopPropagation()}>
                            <Space style={{ float: 'right' }}>
                                <Button
                                    disabled={item.applyAllocationID == applyAllocation.applyAllocationID}
                                    size='small'
                                    color='primary'
                                    onClick={() => {
                                        setApplyAllocation(item);
                                        setVisible3(false);
                                    }}
                                >
                                    确认切换
                                </Button>
                            </Space>
                        </div>
                    </Card>
                })}
            </Card>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </div>
    )

    useEffect(() => {
        var params = EUtils.getUrlParams();
        if (params && params.aid) {
            setApplyAllocationID(params.aid);
        } else {
            loadData();
        }

        if (params && params.id) {
            setApplyOrderID(params.id)
        } else {
            formRef.resetFields();
        }

    }, [])

    useEffect(() => {
        if (applyAllocationID) {
            loadData();
        }
    }, [applyAllocationID])

    useEffect(() => {
        if (applyOrderID) {
            loadOrderData();
        }
    }, [applyOrderID])

    const loadData = () => {
        var request = { applyAllocationID: applyAllocationID };
        Server.Post(URL.ApplyAllocation.QueryApplyAllocation, request).then((response: any) => {
            if (response.isSuccess) {
                setApplyAllocation(response);
            } else {
                Toast.show(response.returnMsg);
            }
        })
    }

    const loadOrderData = () => {
        var request = { applyOrderID: applyOrderID };
        Server.Post(URL.ApplyOrder.QueryApplyOrder, request).then((response: any) => {
            if (response.isSuccess) {
                formRef.setFieldsValue({
                    applyOrderID: response.applyOrderID,
                    applyOrderNO: response.applyOrderNO,
                    applyTypeEnum: response.applyTypeEnum,
                    applyType: { value: response.applyTypeEnum, label: response.applyType },
                    partName: response.partName,
                    packages: { value: response.packages, label: response.packages },
                    eaQty: response.eaQty,
                    startAddress: response.startAddress,
                    destAddress: response.destAddress,
                    truckNO: response.truckNO ?? '',
                    transportNO: response.transportNO ?? '',
                    insureAmount: response.insureAmount,
                    remark: response.remark ?? '',
                    applyAllocationID: applyAllocation?.applyAllocationID,
                    applyAllocationNO: applyAllocation?.applyAllocationNO
                });
                setApplyType({ value: response.applyTypeEnum, label: response.applyType });
                setPackage({ value: response.packages, label: response.packages });
                if (response.requiredTime) {
                    setRequiredTime(dayjs(response.requiredTime).format('YYYY-MM-DD'));
                }
                setCarrierTime(dayjs(response.carrierTime).format('YYYY-MM-DD'));
                setInsureAmount(response.insureAmount);
                setValidTruckNO(response.truckNO && response.truckNO != '');
                setValidTransportNO(response.transportNO && response.transportNO != '');
                setStateEnum(response.stateEnum);
            } else {
                Toast.show(response.returnMsg);
            }
        })
    }

    const saveApplyOrder = () => {
        formRef.validateFields();
        var values = formRef.getFieldsValue();
        if (isValidate(values)) {
            var request = {
                applyOrderID: applyOrderID,
                applyOrderNO: values.applyOrderNO ?? '',
                applyTypeEnum: applyType?.value ?? 0,
                applyType: applyType == 0 ? '即时申报' : '延迟申报',
                applyTime: dayjs().format('YYYY-MM-DD'),
                requiredTime: requiredTime ? dayjs(requiredTime) : dayjs(),
                carrierTime: dayjs(carrierTime).format('YYYY-MM-DD'),
                partName: values.partName,
                packages: packages.label,
                eaQty: values.eaQty,
                startAddress: values.startAddress,
                destAddress: values.destAddress,
                truckNO: values.truckNO ?? '',
                transportNO: values.transportNO ?? '',
                insureAmount: insureAmount,
                remark: values.remark ?? '',
                applyAllocationID: applyAllocation?.applyAllocationID,
                applyAllocationNO: applyAllocation?.applyAllocationNO
            };
            Server.Post(URL.ApplyOrder.SaveApplyOrder, request).then((response: any) => {
                if (response.isSuccess) {
                    Modal.confirm({
                        title: '查看申报订单',
                        confirmText: '立即跳转',
                        cancelText: '继续申报',
                        content: <div>
                            <p>申报订单[{response.applyOrderNO}]已暂存成功，需要提交后邮件至保司，是否立即跳转查看详情？</p>
                            <p>立即跳转：跳转查看申报详情</p>
                            <p>继续申报：放弃跳转，可至申报记录列表中查询申报订单，可修改或提交后邮件至保司</p>
                        </div>,
                        onConfirm: () => {
                            window.location.href = '/applyOrderDetail?id=' + response.applyOrderID;
                        },
                        onCancel: () => {
                            window.location.href = '/applyOrderCreate';
                        }
                    })

                } else {
                    Toast.show(response.returnMsg);
                }
            })
        }
    }

    const isValidate = (values: any) => {
        if (values.partName == '') {
            Toast.show('请输入货物品名');
            return false;
        }
        if (values.eaQty == '') {
            Toast.show('请输入货物件数');
            return false;
        }
        if (values.startAddress == '') {
            Toast.show('请输入始发地');
            return false;
        }
        if (values.destAddress == '') {
            Toast.show('请输入目的地');
            return false;
        }
        if (values.truckNO == '' && values.transportNO == '') {
            Toast.show('请输入车牌号或运单号');
            return false;
        }
        return true;
    }

    const saveAndSubmitApplyOrder = () => {
        formRef.validateFields();
        var values = formRef.getFieldsValue();
        if (isValidate(values)) {
            var request = {
                applyOrderID: applyOrderID,
                applyOrderNO: values.applyOrderNO ?? '',
                applyTypeEnum: applyType?.value ?? 0,
                applyType: applyType == 0 ? '即时申报' : '延迟申报',
                applyTime: dayjs().format('YYYY-MM-DD'),
                requiredTime: requiredTime ? dayjs(requiredTime) : dayjs(),
                carrierTime: dayjs(carrierTime).format('YYYY-MM-DD'),
                partName: values.partName,
                packages: packages.label,
                eaQty: values.eaQty,
                startAddress: values.startAddress,
                destAddress: values.destAddress,
                truckNO: values.truckNO ?? '',
                transportNO: values.transportNO ?? '',
                insureAmount: insureAmount,
                remark: values.remark ?? '',
                applyAllocationID: applyAllocation.applyAllocationID,
                applyAllocationNO: applyAllocation.applyAllocationNO
            };
            Server.Post(URL.ApplyOrder.SaveAndSubmitApplyOrder, request).then((response: any) => {
                if (response.isSuccess) {
                    Modal.confirm({
                        title: '查看申报订单',
                        confirmText: '立即跳转',
                        cancelText: '继续申报',
                        content: <div>
                            <p>申报订单[BDSB1708353388]已生成并邮件提交至保司，立即跳转查看详情？</p>
                            <p>立即跳转：跳转查看申报详情</p>
                            <p>继续申报：放弃跳转，可至申报记录列表中查询申报订单，可修改或提交后邮件至保司</p>
                        </div>,
                        onConfirm: () => {
                            window.location.href = '/applyOrderDetail?id=' + response.applyOrderID;
                        },
                        onCancel: () => {
                            window.location.href = '/applyOrderCreate';
                        }
                    })
                } else {
                    Toast.show(response.returnMsg);
                }
            })
        }

    }

    const deleteApplyOrder = () => {
        Modal.confirm({
            title: '删除确认',
            confirmText: '确定',
            cancelText: '取消',
            content: <div>
                <p>确认删除申报单，一旦删除将无法恢复？</p>
                <p>确定：立即删除</p>
                <p>取消：放弃删除</p>
            </div>,
            onConfirm: () => {
                var request = { applyOrderID: applyOrderID };
                Server.Post(URL.ApplyOrder.DeleteApplyOrder, request).then((response: any) => {
                    if (response.isSuccess) {
                        Toast.show('删除成功');
                        window.location.href = '/applyOrder';
                    } else {
                        Toast.show(response.returnMsg);
                    }
                })
            },
            onCancel: () => {

            }
        })
    }

    const cancelBooking = () => {
        window.location.href = '/applyAllocation'
    }

    return <div style={{ marginTop: '40px', marginBottom: '60px' }}>
        <TopNavBar title={'立即申报'} />
        {applyAllocation?.applyAllocationID != '00000000-0000-0000-0000-000000000000' ?
            <>
                <div>
                    <Card
                        title={<a style={{ fontWeight: 'bold' }}>保单编码：{applyAllocation?.applyAllocationNO ?? ''}</a>}
                        extra={<>
                            <Space>
                                <Button size='small' onClick={() => {
                                    setVisible3(true)
                                    setHasMore(true);
                                }}><MoreOutline />切换保单</Button>
                            </Space>
                        </>}
                        style={{ borderRadius: 0, padding: '0 5px', marginBottom: '5px' }}
                        bodyStyle={{ margin: 0, padding: 0 }}
                    >
                        <Grid columns={4}>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        协议号:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.agreementNO ?? ''} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        投保人:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.insureBy ?? ''} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        被保人:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.insuredBy ?? ''} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        保险公司:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.companyName ?? ''} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        产品名称:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.applyProductName ?? ''} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        生效日期:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.effectTime ? dayjs(applyAllocation?.effectTime).format('YYYY-MM-DD') : ''} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        失效日期:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.expireTime ? dayjs(applyAllocation?.expireTime).format('YYYY-MM-DD') : ''} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        投保限额:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={(applyAllocation?.singleLimit ?? 0) + '万元'} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        总保额:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={(applyAllocation?.amount ?? 0) + '万元'} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        剩余保额:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={(applyAllocation?.amount ?? 0 - applyAllocation?.usedAmount ?? 0) + '万元'} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        免赔额:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开'
                                            collapseText='收起' direction='end' content={applyAllocation?.deductibleAmountDesc ?? ''} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        承保区域:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开'
                                            collapseText='收起' direction='end' content={applyAllocation?.insureArea ?? ''} />
                                    </span>
                                </div>
                            </Grid.Item>
                        </Grid>
                    </Card>
                    <Popup
                        visible={visible3}
                        onClose={() => {
                            setVisible3(false)
                        }}
                        onMaskClick={() => {
                            setVisible3(false)
                        }}
                        position='left'
                        bodyStyle={{ width: '100vw', height: '100vh' }}
                    >
                        {mockContent}
                    </Popup>
                </div>
                <div>
                    <Card
                        title={'申报填写'}
                        headerStyle={{
                            color: '#1677ff',
                            fontWeight: 'bold',
                            height: '35px'
                        }}
                        style={{ borderRadius: 0, padding: '5px', marginBottom: '5px' }}
                        bodyStyle={{ margin: 0, padding: 0 }}
                    >
                        <Form
                            form={formRef} layout='horizontal' hasFeedback
                            style={{ margin: 0, padding: 0 }}>
                            <Form.Item hidden label={'applyOrderNO'} name={'applyOrderNO'} initialValue={''}>
                            </Form.Item>
                            <Form.Item label={'申报类型'} name={'applyType'} initialValue={applyType} >
                                <label onClick={() => {
                                    setApplyTypeVisible(true)
                                }} >{applyType?.label}</label>
                                <Picker
                                    columns={[
                                        [
                                            { value: '0', label: '即时申报' },
                                            { value: '1', label: '约定时间申报' },
                                        ]
                                    ]}
                                    visible={applyTypeVisible}
                                    onClose={() => {
                                        setApplyTypeVisible(false)
                                    }}
                                    value={[applyType?.value ?? '即时申报']}
                                    onSelect={(val, extend) => {
                                        setApplyType(extend.items[0])
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label={'约定时间'} hidden={applyType.value == '0'} name='requiredTime' rules={[{ message: '请选择约定时间' }]}>
                                <DatePicker
                                    visible={requiredTimeVisible}
                                    onClose={() => {
                                        setRequiredTimeVisible(false)
                                    }}
                                    onSelect={(e) => {
                                        if (e) {
                                            setRequiredTime(e);
                                        }
                                    }}
                                    onConfirm={e => {
                                        if (e) {
                                            setRequiredTime(e);
                                        }
                                    }}
                                    precision='minute'
                                    value={requiredTime}
                                    min={new Date()}
                                >
                                    {value =>
                                        <div onClick={() => { setRequiredTimeVisible(true) }}>  {value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '请选择日期时间'}</div>
                                    }
                                </DatePicker>
                            </Form.Item>
                            <Form.Item label={'起运时间'} name='carrierTime' rules={[{ message: '请选择起运时间' }]}>
                                <DatePicker
                                    visible={carrierTimeVisible}
                                    onClose={() => {
                                        setCarrierTimeVisible(false)
                                    }}
                                    onSelect={(e) => {
                                        if (e) {
                                            setCarrierTime(e);
                                        }
                                    }}
                                    value={carrierTime}
                                    min={new Date()}
                                >
                                    {value =>
                                        <div onClick={() => { setCarrierTimeVisible(true) }}>
                                            {value ? dayjs(value).format('YYYY-MM-DD') :
                                                dayjs(carrierTime).format('YYYY-MM-DD')}
                                        </div>
                                    }
                                </DatePicker>
                            </Form.Item>
                            <Form.Item label={'货物品名'} name={'partName'} initialValue={''} rules={[{ required: true, message: '请输入货物品名' }]}>
                                <Input clearable placeholder="请输入货物品名" />
                            </Form.Item>
                            <Form.Item label={'货物包装'} name={'packages'} initialValue={packages} rules={[{ required: true, message: '请输入货物包装' }]}>
                                <label onClick={() => {
                                    setPackagesVisible(true)
                                }} >{packages?.label}</label>
                                <Picker
                                    columns={[
                                        [
                                            { value: '散装及托盘包装', label: '散装及托盘包装' },
                                            { value: '捆包状包装', label: '捆包状包装' },
                                            { value: '袋状包装', label: '袋状包装' },
                                            { value: '箱状包装', label: '箱状包装' },
                                            { value: '桶状包装', label: '桶状包装' },
                                            { value: '裸状包装', label: '裸状包装' },
                                            { value: '其他形状包装', label: '其他形状包装' }
                                        ]
                                    ]}
                                    visible={packagesVisible}
                                    onClose={() => {
                                        setPackagesVisible(false)
                                    }}
                                    value={[packages?.value ?? '其他形状包装']}
                                    onSelect={(val, extend) => {
                                        setPackage(extend.items[0])
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label={'货物件数'} name={'eaQty'} initialValue={''} rules={[{ required: true, message: '请输入货物件数' }]}>
                                <Input clearable placeholder="请输入货物件数" />
                            </Form.Item>
                            <Form.Item label={'始发地'} name={'startAddress'} initialValue={''} rules={[{ required: true, message: '请输入始发地' }]}>
                                <Input clearable placeholder="请输入始发地" />
                            </Form.Item>
                            <Form.Item label={'目的地'} name={'destAddress'} initialValue={''} rules={[{ required: true, message: '请输入目的地' }]}>
                                <Input clearable placeholder="请输入目的地" />
                            </Form.Item>
                            <Form.Item label={'车牌号'} name={'truckNO'} initialValue={''} rules={[{ required: validTruckNO, message: '请填写车牌号' }]}>
                                <Input clearable placeholder="请输入车牌号" onChange={(e) => {
                                    if (e && e.trim() != '') {
                                        setValidTransportNO(false);
                                    } else {
                                        setValidTransportNO(true);
                                    }
                                }} />
                            </Form.Item>
                            <Form.Item label={'运单号'} name={'transportNO'} initialValue={''} rules={[{ required: validTransportNO, message: '请填写运单号' }]}>
                                <Input clearable placeholder="请输入运单号" onChange={(e) => {
                                    if (e && e.trim() != '') {
                                        setValidTruckNO(false);
                                    } else {
                                        setValidTruckNO(true);
                                    }
                                }} />
                            </Form.Item>
                            <Form.Item label={'保额'} name={'insureAmount'} initialValue={1} extra={<>万元</>}>
                                <Stepper min={1} max={100} style={{ width: '100%' }} onChange={(e: any) => {
                                    setInsureAmount(e && e > 1 ? e : 1)
                                }} value={insureAmount} digits={0} />
                            </Form.Item>
                            <Form.Item label={'说明'} name={'remark'} initialValue={''}>
                                <Input clearable placeholder="请输入申报说明" />
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
                <div style={{
                    position: 'fixed', bottom: 0, height: '45px', width: 'calc(100%)'
                    , backgroundColor: 'white', zIndex: 99
                }}>
                    <div style={{
                        padding: '5px 2px',
                        height: '100%', maxWidth: '30%', float: 'left'
                        , fontSize: '12px'
                        , lineHeight: '35px', backgroundColor: 'rgb(22 119 255)'
                        , color: 'white', fontWeight: 'bold'
                    }}>保额：{insureAmount}万元</div>
                    <Space style={{ float: 'right', margin: '8px 2px' }}>
                        {stateEnum == 0 ? <Button size='small' onClick={saveApplyOrder}>暂存</Button> : ''}
                        {stateEnum == 0 ? <Button color='primary' size='small' onClick={saveAndSubmitApplyOrder}>申报</Button> : ''}
                        {applyOrderID && stateEnum == 0 ? <Button color='danger' size='small' onClick={deleteApplyOrder}>删除</Button> : ''}
                        <Button size='small' onClick={cancelBooking}>取消</Button>
                    </Space>
                </div>
            </> : <><Result
                status='info'
                title='信息提示'
                description='暂无保单数据，请联系管理员签约保单'
            />
                <div style={{
                    position: 'fixed', bottom: 0, height: '45px', width: 'calc(100%)'
                    , backgroundColor: 'white', zIndex: 99
                }}>
                    <Space style={{ float: 'right', margin: '8px 2px' }}>
                        <Button size='small' onClick={cancelBooking}>取消</Button>
                    </Space>
                </div>
            </>}
    </div>
}
export default ApplyOrderCreate;

