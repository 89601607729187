import { Button, Card, Ellipsis, Grid, Modal, SearchBar, Space, Tabs, Tag, Toast } from "antd-mobile";
import TopNavBar from "../../../components/TopNavBar/TopNavBar";
import { AntOutline, RightOutline } from 'antd-mobile-icons'
import { useEffect, useState } from "react";
import EUtils from "../../../utils/EUtils";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import dayjs from 'dayjs';
import ApplyOrderDetailEmail from "./ApplyOrderDetailEmail";

const ApplyOrderDetail = () => {
    const [applyOrderID, setApplyOrderID] = useState(null);
    const [applyOrder, setApplyOrder] = useState<any>(null);

    const [applyAllocation, setApplyAllocation] = useState<any>(null);

    useEffect(() => {
        var params = EUtils.getUrlParams();
        if (params) {
            setApplyOrderID(params.id);
        }
    }, [])

    useEffect(() => {
        if (applyOrderID) {
            loadData();
        }
    }, [applyOrderID])

    useEffect(() => {
        if (applyOrder) {
            loadAllocationData();
        }
    }, [applyOrder])

    const loadData = () => {
        var request = { applyOrderID: applyOrderID };
        Server.Post(URL.ApplyOrder.QueryApplyOrder, request).then((response: any) => {
            if (response.isSuccess) {
                setApplyOrder(response);
            } else {
                Toast.show(response.returnMsg);
            }
        })
    }

    const loadAllocationData = () => {
        var request = { applyAllocationID: applyOrder.applyAllocationID };
        Server.Post(URL.ApplyAllocation.QueryApplyAllocation, request).then((response: any) => {
            if (response.isSuccess) {
                setApplyAllocation(response);
            } else {
                Toast.show(response.returnMsg);
            }
        })
    }

    const submitApplyOrder = () => {
        Modal.confirm({
            title: '提交申报确认',
            confirmText: '确定',
            cancelText: '取消',
            content: <div>
                <p>申报订单[{applyOrder.applyOrderNO}]，确认要提交申报，一旦提交申报将不能恢复，请慎重操作</p>
                <p>确定：提交申报</p>
                <p>取消：放弃提交</p>
            </div>,
            onConfirm: () => {
                var request = {
                    applyOrderID: applyOrder.applyOrderID,
                    applyOrderNO: applyOrder.applyOrderNO
                };
                Server.Post(URL.ApplyOrder.SubmitApplyOrder, request).then((response: any) => {
                    if (response.isSuccess) {
                        Toast.show('提交申报成功');
                        window.location.href = '/applyOrderDetail?id=' + applyOrder.applyOrderID;
                    } else {
                        Toast.show(response.returnMsg);
                    }
                })
            },
            onCancel: () => {

            }
        })
    }

    const deleteApplyOrder = () => {
        Modal.confirm({
            title: '删除确认',
            confirmText: '确定',
            cancelText: '取消',
            content: <div>
                <p>确认删除申报单，一旦删除将无法恢复？</p>
                <p>确定：立即删除</p>
                <p>取消：放弃删除</p>
            </div>,
            onConfirm: () => {
                var request = { applyOrderID: applyOrderID };
                Server.Post(URL.ApplyOrder.DeleteApplyOrder, request).then((response: any) => {
                    if (response.isSuccess) {
                        Toast.show('删除成功');
                        window.location.href = '/applyOrder';
                    } else {
                        Toast.show(response.returnMsg);
                    }
                })
            },
            onCancel: () => {

            }
        })
    }

    return <div style={{ marginTop: '40px', marginBottom: '60px' }}>
        <TopNavBar back title={'申报记录详情'} />
        <div>
            <Card
                title={'申报记录'}
                extra={<>
                    <Space>
                        {(applyOrder?.tags ?? []).map((item: any) => {
                            return <Tag color={item.color}>{item.name}</Tag>;
                        })}
                    </Space>
                </>}
                style={{ borderRadius: 0, padding: '5px', marginBottom: '5px' }}
                bodyStyle={{ margin: 0, padding: 0 }}
            >
                <Grid columns={4}>
                    <Grid.Item span={4}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                申报单号:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyOrder?.applyOrderNO} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                申报时间:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={dayjs(applyOrder?.applyOrderNO.applyTime).format('YYYY-MM-DD HH:mm')} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                申报类型:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyOrder?.applyType} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                发送时间:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyOrder?.sendTime ? dayjs(applyOrder?.sendTime).format('YYYY-MM-DD') : ''} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                保额:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyOrder?.insureAmount + '万元'} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                承运时间:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={dayjs(applyOrder?.carrierTime).format('YYYY-MM-DD')} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                货物品名:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyOrder?.partName} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                货物包装:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyOrder?.packages} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                货物件数:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyOrder?.eaQty} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                始发地:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyOrder?.startAddress} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                目的地:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyOrder?.destAddress} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                车牌号:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyOrder?.truckNO} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                运单号:
                            </div>
                            <span>
                                <Ellipsis direction='end' content={applyOrder?.transportNO} />
                            </span>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div>
                            <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                说明:
                            </div>
                            <span>
                                <Ellipsis expandText='展开'
                                    collapseText='收起' direction='end' content={applyOrder?.remark} />
                            </span>
                        </div>
                    </Grid.Item>
                </Grid>
            </Card>
            <Tabs style={{ '--content-padding': '0px' }}>
                <Tabs.Tab title='保单信息' key='fruits' >
                    <Card
                        title={<>
                            <Space>
                                <Tag color={'warning'}>申报合计：{applyAllocation?.usedAmount}万元</Tag>
                                {(applyAllocation?.tags ?? []).map((item: any) => {
                                    return <Tag color={item.color}>{item.name}</Tag>;
                                })}
                            </Space>
                        </>}
                        extra={<>
                            <a onClick={() => {
                                window.location.href = '/applyAllocationDetail?id=' + applyAllocation.applyAllocationID;
                            }}>查看详情<RightOutline /></a>
                        </>}
                        style={{ borderRadius: 0, padding: '5px' }}
                        bodyStyle={{ margin: 0, padding: 0 }}
                    >
                        <Grid columns={4}>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        协议号:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.agreementNO} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        投保人:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.insureBy} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        被保人:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.insuredBy} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        保险公司:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.companyName} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        产品名称:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.applyProductName} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        生效日期:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={dayjs(applyAllocation?.effectTime).format('YYYY-HH-DD')} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        失效日期:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={dayjs(applyAllocation?.expireTime).format('YYYY-HH-DD')} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        投保限额:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.singleLimit + '万元'} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        总保额:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={applyAllocation?.amount + '万元'} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        剩余保额:
                                    </div>
                                    <span>
                                        <Ellipsis direction='end' content={(applyAllocation?.amount - applyAllocation?.usedAmount) + '万元'} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div style={{ borderBottom: '1px solid #e5e5e5' }}>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        免赔额:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开' defaultExpanded
                                            collapseText='收起' direction='end' content={applyAllocation?.deductibleAmountDesc} />
                                    </span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={4}>
                                <div>
                                    <div style={{ width: '60px', backgroundColor: '#e5e5e5', float: 'left', textAlign: 'left', margin: '0 2px' }}>
                                        承保区域:
                                    </div>
                                    <span>
                                        <Ellipsis expandText='展开' defaultExpanded
                                            collapseText='收起' direction='end' content={applyAllocation?.insureArea} />
                                    </span>
                                </div>
                            </Grid.Item>
                        </Grid>
                    </Card>
                </Tabs.Tab>
                <Tabs.Tab title='邮件记录' key='vegetables'>
                    <ApplyOrderDetailEmail
                        applyOrderID={applyOrderID}
                        applyOrder={applyOrder} />
                </Tabs.Tab>
            </Tabs>
        </div>
        <div style={{
            position: 'fixed', bottom: 0, height: '45px', width: 'calc(100%)'
            , backgroundColor: 'white', zIndex: 99
        }}>
            <div style={{
                padding: '5px',
                height: '100%', maxWidth: '30%', float: 'left'
                , fontSize: '12px'
                , lineHeight: '35px', backgroundColor: 'rgb(22 119 255)'
                , color: 'white', fontWeight: 'bold'
            }}>保额：{applyOrder?.insureAmount}万元</div>
            <Space style={{
                float: 'right', padding: '10px',
                display: applyOrder?.stateEnum == 0 ? '' : 'none'
            }}>
                <Button size='small' onClick={() => {
                    window.location.href = '/applyOrderCreate?id=' + applyOrderID
                }}>编辑</Button>
                <Button color='primary' size='small' onClick={submitApplyOrder}>申报</Button>
                <Button color='danger' size='small' onClick={deleteApplyOrder}>删除</Button>
            </Space>
        </div>
    </div>
}
export default ApplyOrderDetail;