import { Button, Form, Input, Result, Tabs, Toast } from "antd-mobile";
import { useEffect, useState } from "react";
import BgImage from '../../imgs/logo.jpg'
import Server from "../../server/Server";
import URL from "../../server/URL";

let timer: string | number | NodeJS.Timeout | null | undefined = null;
const Login = () => {
    const [formRef] = Form.useForm()
    const [loginType, setLoginType] = useState(0);
    const [isSend, setIsSend] = useState(false);
    const [codeSecond, setCodeSecond] = useState(120);

    const onSubmit = (values: any) => {
        var params = {
            loginType: loginType,
            account: values.account ?? '',
            password: values.password ?? '',
            tel: values.tel ?? '',
            code: values.code ?? ''
        };
        Server.Post(URL.User.UserLogin, params).then((response: any) => {
            if (response.isSuccess) {
                let tokenKey = process.env.REACT_APP_TOKEN ?? '';
                localStorage.setItem(tokenKey, response.token);
                localStorage.setItem("EKnown.UserInfo", JSON.stringify({
                    name: response.name,
                    memberTypeEnum: response.memberTypeEnum,
                    memberType: response.memberType,
                    tel: response.tel
                }));
                window.location.href = '/'
            } else {
                Toast.show(response.returnMsg);
            }
        });
    }
    const getMsgCode = () => {
        const values = formRef.getFieldsValue()
        if (values.tel && values.tel != '') {
            var params = { tel: values.tel ?? '' };
            Server.Post(URL.User.GetMsgCode, params).then((response: any) => {
                if (response.isSuccess) {
                    setIsSend(true);
                }
            });
        } else {
            formRef.validateFields(['tel']);
        }
    }
    useEffect(() => {
        if (isSend) {
            setCodeSecond(120);
            startMsgCode();
        } else {
            stopMsgCode();
        }

    }, [isSend])

    const startMsgCode = () => {
        clearInterval(timer ?? undefined);
        timer = setInterval(() => {
            setCodeSecond((value) => {
                if (value == 0) {
                    setIsSend(false);
                    return 0;
                }
                return (value - 1);
            });
        }, 1000);
    }

    const stopMsgCode = () => {
        clearInterval(timer ?? undefined);
    }

    return <div style={{ width: '100%' }}>
        <div style={{
            textAlign: 'center',
            alignContent: 'center',
            height: '200px', width: '200px'
            , backgroundRepeat: 'no-repeat'
            , backgroundPosition: 'center'
            , backgroundSize: 'cover'
        }}>
            {/* <img style={{  display:'block',width:'100%' }} src={BgImage} height={200} /> */}
        </div>
        <Form form={formRef} layout='horizontal' hasFeedback onFinish={onSubmit}
            footer={
                <>
                    <span style={{ padding: '10px', float: 'left' }}>
                        <a href="/register" style={{ textDecoration: 'none' }}>注册账号</a>
                    </span>
                    <span style={{ padding: '10px', float: 'right' }}>
                        <a href="/forgetPwd" style={{ textDecoration: 'none' }}>忘记密码</a>
                    </span>
                    <Button block type='submit' color='primary' size='middle' >
                        登录
                    </Button>
                </>
            }>
            <Tabs style={{ margin: 0, padding: 0 }} onChange={(e: any) => setLoginType(e)}>
                <Tabs.Tab title='账号登录' key={0}>
                    <Form.Item name={'account'} initialValue={''} style={{ margin: 0, padding: 0 }} rules={[{ required: loginType == 0, message: '请输入账号/手机号码' }]}>
                        <Input placeholder="用户账号/手机号" />
                    </Form.Item>
                    <Form.Item name={'password'} initialValue={''} style={{ margin: 0, padding: 0 }} rules={[{ required: loginType == 0, message: '请输入账户密码' }]}>
                        <Input placeholder="用户密码" type="password" />
                    </Form.Item>
                </Tabs.Tab>
                <Tabs.Tab title='手机登录' key={1}>
                    <Form.Item name={'tel'} initialValue={''} style={{ margin: 0, padding: 0 }} rules={[{ required: loginType == 1, pattern: new RegExp(/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/, 'g'), message: '请输入11位手机号码' }]}>
                        <Input placeholder="手机号" />
                    </Form.Item>
                    <Form.Item name={'code'} initialValue={''} style={{ margin: 0, padding: 0 }} extra={<Button size='small' color='primary' disabled={isSend} onClick={() => getMsgCode()}>{isSend ? ('(' + codeSecond + 's)') : '获取验证码'}</Button>}
                        rules={[{ required: loginType == 1, message: '请输入验证码' }]}>
                        <Input placeholder="短信验证码" />
                    </Form.Item>
                </Tabs.Tab>
            </Tabs>
        </Form>
    </div>
}
export default Login;